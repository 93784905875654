<template>
  <main class="page__content--wider">
    <div class="page__head">
      <div
        class="page__head__container align-items-center justify-content-between"
      >
        <div class="page__head__text">
          <h1 class="page__head2__title">Deposit Accounts</h1>
        </div>
        <div class="page__head__actions">
          <div class="d-flex">
            <!--<button
              type="button"
              data-modal="#editColumnModal"
              class="
                mx-2
                button
                page__body__header__button
                button--grey
                text--capital
              "
            >
              <span class="d-inline-flex mr-2">edit columns</span>
            </button>-->

            <!--<div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Custom Columns
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  data-modal="#newDepositPresetModal"
                  >New Column Preset</a
                >
                <a class="dropdown-item text--capital" data-modal="#deleteModal"
                  >Custom Columns</a
                >
              </div>
            </div>-->

            <div class="align-self-center mx-2 dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                @click="toggleFilterModal"
              >
                <!-- <span class="dropdown__icon--left">
                  <img src="../../../assets/img/filter.svg" alt="" srcset="" />
                </span> -->
                <span class="filter_text"> Filter </span>
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item text--capital"
                  onclick="toggleFilter(this)"
                  >No Filter</a
                >
                <a
                  class="dropdown-item text--capital"
                  onclick="toggleFilter(this)"
                  data-modal="#customStoreDepositFormFilter"
                  >Add New Filter</a
                >
                <a
                  class="dropdown-item text--capital"
                  data-modal="#customGroupFilter"
                  >Custom Filter</a
                >
              </div> -->
            </div>

            <!--<div class="align-self-center mx-2 dropdown">
              <button
                class="
                  button button--grey
                  text--capital text--small
                  d-flex
                  align-items-center
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                All branches
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item text--capital">Branch 1</a>
                <a class="dropdown-item text--capital">Brnach 2</a>
              </div>
            </div>-->

            <div class="align-self-center mx-2 dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                export
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  href="javascript:void(0)"
                  @click="pdfDownload"
                  v-if="userCanExportToPDF"
                  class="dropdown-item text--capital"
                  >PDF</a
                >
                <a
                  href="javascript:void(0)"
                  @click="processDownload"
                  v-if="userCanExportToExcel"
                  class="dropdown-item text--capital"
                  >excel</a
                >
                <!-- <download-excel
                  class="dropdown-item text--capital"
                  style="cursor: pointer"
                  :data="download"
                  :fields="json_fields"
                  name="deposits.xls"
                >
                  excel
                </download-excel> -->
                <!-- <a class="dropdown-item text--capital" @click="downloadCSVFile"
                  >CSV</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; width: 50%; font-size: 14px" v-if="filterText">
      <div style="width: 80%">
        {{ filterText }}
      </div>
      <div style="display: flex; width: 10%">
        <span style="cursor: pointer; font-size: 17px" @click="editFilter">
          <i style="padding-right: 5px" class="bi bi-pencil-square"></i>
        </span>
        <span style="cursor: pointer; font-size: 17px" @click="deleteFilter">
          <i style="padding-left: 5px" class="bi bi-x-circle-fill"></i>
        </span>
      </div>
    </div>

    <div class="page__body">
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 13%"
            >
              Product Name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 9%"
            >
              ID
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 11%"
            >
              Branch
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              Account Holder Name
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 11%"
            >
              Approved State
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 11%"
            >
              deposit balance
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              Account Officer
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              Last Modified Date
            </th>
            <!-- <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 9%"
            >
              Action
            </th> -->
          </tr>
        </thead>
        <tbody v-if="loadingDepositData">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="allDepositAccounts.data != ''">
          <tr v-for="(account, i) in allDepositAccounts.data" :key="i">
            <td>
              <span
                @click="viewRoute(account.savingsAccountKey)"
                class="table__body__text table__text text--capital text--primary cursor"
              >
                {{ account.savingsAccountName }}</span
              >
              <!-- <a @click="accountDetails(account)" class="table__body__text table__text text--capital text--primary">{{ account.savingsAccountName }}</a> -->
            </td>
            <!-- <td>
              <span class="table__body__text table__text text--capital">211723847934</span>
            </td> -->
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.uniqueId
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.branchName ? account.branchName : ""
              }}</span>
            </td>
            <td>
              <span
                @click="viewRoute(account.savingsAccountKey)"
                class="table__body__text table__text text--capital text--primary cursor"
              >
                {{ account.accountFirstName }}
                {{ account.accountLastName }}</span
              >
              <!-- <a href="/" class="table__body__text table__text text--capital text--primary">{{ account.accountFirstName }} {{ account.accountLastName }}</a> -->
            </td>
            <td>
              <span
                class="table__body__text table__text text--capital text--primary"
                v-if="account.accountStatus === 'PENDING APPROVAL'"
                >Pending</span
              >
              <span
                class="table__body__text table__text text--capital text--primary"
                v-else-if="account.accountStatus === 'ACTIVE'"
                >Active</span
              >
              <span
                class="table__body__text table__text text--capital text--success"
                v-else-if="account.accountStatus === 'MATURED'"
                >Matured</span
              >
              <span
                class="table__body__text table__text text--capital text--primary"
                v-else-if="account.accountStatus === 'LOCKED'"
                >Locked</span
              >
              <span
                class="table__body__text table__text text--capital text--primary"
                v-else-if="account.accountStatus === 'DORMANT'"
                >Dormant</span
              >
              <span
                class="table__body__text table__text text--capital text--primary"
                v-else-if="account.accountStatus === 'CLOSED'"
                >Closed</span
              >
              <span
                class="table__body__text table__text text--capital text--primary"
                v-else-if="account.accountStatus === 'CLOSED WRITTEN OFF'"
                >Closed written off</span
              >
              <span
                class="table__body__text table__text text--capital text--primary"
                v-else-if="account.accountStatus === 'CLOSED REJECTED'"
                >closed rejected</span
              >
              <span
                class="table__body__text table__text text--capital text--success"
                v-else-if="account.accountStatus === 'WITHDRAWN'"
                >withdrawn</span
              >
              <span
                class="table__body__text table__text text--capital text--success"
                v-else
                >Approved</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.balance
                  | currency(
                    account.currency ? account.currency.currencySymbol : null
                  )
              }}</span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">
                {{ account.accountOfficerName }}</span
              >
            </td>
            <td>
              <span class="table__body__text table__text text--capital">{{
                account.lastModifiedDate | moment
              }}</span>
            </td>
            <!-- <td>
              <div class="align-self-center dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  action
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    >edit</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    data-modal="#confirmModal"
                    v-if="account.accountStatus !== 'CLOSED'"
                    @click="openCreateAccountModal(account)"
                    >Change account state</a
                  >
                </div>
              </div>
            </td> -->
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="12">
              <div
                class="d-flex flex-column align-items-center justify-content-center p5"
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >No data available</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :allItems="allDepositAccounts"
        :size="size"
        @update-created-task="getUpdatedDeposits"
        @change-page-index="changePageIndex"
      />
    </div>

    <!-- Update Deposit Account Status -->
    <createAccount v-bind:show="showAccountStatusModal">
      <template v-slot:modal-head>
        <div class="modal__head__icon">
          <a
            class="modal__close modal__close--black"
            @click="closeAccountStatusModal()"
          >
            <ion-icon name="arrow-back-outline"></ion-icon>
          </a>
        </div>
      </template>
      <template v-slot:modal-title>
        <h5 class="modal__head__title text--capital">
          Change Deposit Account Status
        </h5>
      </template>
      <template v-slot:modal-body>
        <form>
          <div>
            <div class="col-12 no--padding">
              <div class="form__item">
                <label class="form__label">Select Customer or Group</label>
                <select
                  class="select select--lg"
                  required
                  v-model.trim="account.accountStatus"
                >
                  <option value="" selected default>Select account type</option>
                  <option
                    v-for="item in accountStatusList"
                    :key="item.id"
                    :value="item.name"
                    v-show="
                      item.name.toLowerCase() !==
                      account.accountStatus.toLowerCase()
                    "
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form__item">
              <div class="form__action">
                <button
                  class="button form__button form__button--lg w-30 save-changes"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button>
                <button
                  class="button form__button form__button--lg save-changes"
                  @click.prevent="changeAccountStatus"
                  v-else
                >
                  Submit
                </button>
                <a
                  data-dismiss="#newDepositModal"
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="closeAccountStatusModal()"
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        </form>
      </template>
    </createAccount>

    <!-- Filter -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Deposit Account'"
      filterCategory="deposit"
    />

    <DeleteModal
      :show="!userCanViewDepositAccount"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="editColumnModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#editColumnModal"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Editing Custom Deposit Accounts Preset
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Available Columns</label>
                <select class="select select--lg" required>
                  <option value="" disabled selected>
                    Select available columns
                  </option>
                  <option value="">option 1</option>
                  <option value="">option 2</option>
                  <option value="">option 3</option>
                </select>
              </div>

              <div class="form__item">
                <label class="form__label">Selected Columns</label>
                <div class="form__item_dottedbg px-5 py-3">
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      full name
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      ID
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      customer state
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      account officer
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      loan deposit
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      deposit balance
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      last modified
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row align-items-end">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">sort by</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Title"
                    />
                  </div>
                  <div class="form__item">
                    <label
                      for="includeTotal"
                      class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                      >Include Totals
                      <input type="checkbox" id="includeTotal" class="form-" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <select class="select select--lg" required>
                      <option value="" disabled selected>
                        ascending order
                      </option>
                      <option value="">option 1</option>
                      <option value="">option 2</option>
                      <option value="">option 3</option>
                    </select>
                  </div>
                  <div class="form__item">
                    <label
                      for="includeTimestamp"
                      class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                      >Include Timestamp
                      <input
                        type="checkbox"
                        id="includeTimestamp"
                        class="form-"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#editColumnModal"
                    class="button form__button form__button--lg w-30 save-changes"
                    type="button"
                  >
                    apply changes
                  </button>
                  <a
                    role="button"
                    data-dismiss="#editColumnModal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="newDepositPresetModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#newDepositPresetModal"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Editing Custom Deposit Accounts Preset
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Preset Name</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Enter Preset Name"
                />
              </div>
              <div class="d-flex justify-content-between">
                <div class="form__item">
                  <label
                    for="sharedPreset"
                    class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                    >Shared Preset
                    <input type="checkbox" id="sharedPreset" class="form-" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="form__item">
                  <label
                    for="defaultPreset"
                    class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                    >Default Preset
                    <input type="checkbox" id="defaultPreset" class="form-" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="form__item">
                <label class="form__label">Available Columns</label>
                <select class="select select--lg" required>
                  <option value="" disabled selected>
                    Select available columns
                  </option>
                  <option value="">option 1</option>
                  <option value="">option 2</option>
                  <option value="">option 3</option>
                </select>
              </div>

              <div class="form__item">
                <label class="form__label">Selected Columns</label>
                <div class="form__item_dottedbg px-5 py-3">
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      full name
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      ID
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      customer state
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      account officer
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      loan deposit
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      deposit balance
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                  <div
                    class="d-flex my-3 align-items-center justify-content-between"
                  >
                    <p
                      class="text--regular text--black form__item_text3 text--capital"
                    >
                      last modified
                    </p>
                    <button
                      type="button"
                      class="mx-2 button button--sm button--cancel text--capital"
                    >
                      <span class="d-inline-flex mr-2">delete</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row align-items-end">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">sort by</label>
                    <input
                      type="text"
                      class="input form__input form__input--lg"
                      placeholder="Enter Title"
                    />
                  </div>
                  <div class="form__item">
                    <label
                      for="includeTotalP"
                      class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                      >Include Totals
                      <input type="checkbox" id="includeTotalP" class="form-" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <select class="select select--lg" required>
                      <option value="" disabled selected>
                        ascending order
                      </option>
                      <option value="">option 1</option>
                      <option value="">option 2</option>
                      <option value="">option 3</option>
                    </select>
                  </div>
                  <div class="form__item">
                    <label
                      class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                      >Include Timestamp
                      <input type="checkbox" class="form-" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#newDepositPresetModal"
                    class="button form__button form__button--lg w-30 save-changes"
                    type="button"
                  >
                    apply changes
                  </button>
                  <a
                    role="button"
                    data-dismiss="#newDepositPresetModal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="customStoreDepositFormFilter"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#customStoreDepositFormFilter"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Store Deposit Accounts Filter
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <div class="p-5 border-grey border-radius">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Name</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Enter Name</option>
                          <option value="" selected>Option 1</option>
                          <option value="">Option 2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Options</label>
                        <label
                          for="sharedFilter"
                          class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                          >Shared Filter
                          <input
                            type="checkbox"
                            id="sharedFilter"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-end justify-content-between">
                    <div class="col-6">
                      <div class="form__item mb-0">
                        <label class="form__label">Where</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Select Where</option>
                          <option value="" selected>Loan Balance</option>
                          <option value="">Account Balance</option>
                        </select>
                      </div>
                    </div>
                    <div class="px-4">
                      <button
                        type="button"
                        class="button button--cancel text--capital"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="p-5 border-grey border-radius">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Name</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Enter Name</option>
                          <option value="" selected>Option 1</option>
                          <option value="">Option 2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Options</label>
                        <label
                          for="sharedFilter1"
                          class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                          >Shared Filter
                          <input
                            type="checkbox"
                            id="sharedFilter1"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-end justify-content-between">
                    <div class="col-6">
                      <div class="form__item mb-0">
                        <label class="form__label">Where</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Select Where</option>
                          <option value="" selected>Loan Balance</option>
                          <option value="">Account Balance</option>
                        </select>
                      </div>
                    </div>
                    <div class="px-4">
                      <button
                        type="button"
                        class="button button--cancel text--capital"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="text-right row mt-4 mb5">
                  <div class="col ml-auto">
                    <div>
                      <select class="select select--lg w-auto" required>
                        <option value="" disabled>Select Filter</option>
                        <option value="" selected>Match All</option>
                        <option value="">Match None</option>
                      </select>
                      <button
                        type="button"
                        class="ml-5 button button--grey text--black text--capital"
                      >
                        <span class="d-inline-flex">Add Filter</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#customStoreDepositFormFilter"
                    class="button form__button form__button--lg w-30 save-changes"
                    type="button"
                  >
                    apply
                  </button>
                  <a
                    role="button"
                    data-dismiss="#customStoreDepositFormFilter"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__right"
      id="customDepositAccountFilter"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                data-dismiss="#customDepositAccountFilter"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              Custom Deposit Accounts Filter
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <div class="p-5 border-grey border-radius">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Name</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Enter Name</option>
                          <option value="" selected>Option 1</option>
                          <option value="">Option 2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Options</label>
                        <label
                          for="sharedFilter2"
                          class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                          >Shared Filter
                          <input
                            type="checkbox"
                            id="sharedFilter2"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-end justify-content-between">
                    <div class="col-6">
                      <div class="form__item mb-0">
                        <label class="form__label">Where</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Select Where</option>
                          <option value="" selected>Loan Balance</option>
                          <option value="">Account Balance</option>
                        </select>
                      </div>
                    </div>
                    <div class="px-4">
                      <button
                        type="button"
                        class="button button--cancel text--capital"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="p-5 border-grey border-radius">
                  <div class="row">
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Name</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Enter Name</option>
                          <option value="" selected>Option 1</option>
                          <option value="">Option 2</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form__item">
                        <label class="form__label">Options</label>
                        <label
                          for="sharedFilter4"
                          class="d-block has-checkbox page__body__header__label text--grey text--capital mb-3 mr-3"
                          >Shared Filter
                          <input
                            type="checkbox"
                            id="sharedFilter4"
                            class="form-"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-end justify-content-between">
                    <div class="col-6">
                      <div class="form__item mb-0">
                        <label class="form__label">Where</label>
                        <select class="select select--lg" required>
                          <option value="" disabled>Select Where</option>
                          <option value="" selected>Loan Balance</option>
                          <option value="">Account Balance</option>
                        </select>
                      </div>
                    </div>
                    <div class="px-4">
                      <button
                        type="button"
                        class="button button--cancel text--capital"
                      >
                        <span class="d-inline-flex">delete</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="text-right row mt-4 mb5">
                  <div class="col ml-auto">
                    <div>
                      <select class="select select--lg w-auto" required>
                        <option value="" disabled>Select Filter</option>
                        <option value="" selected>Match All</option>
                        <option value="">Match None</option>
                      </select>
                      <button
                        type="button"
                        class="ml-5 button button--grey text--black text--capital"
                      >
                        <span class="d-inline-flex">Add Filter</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#customDepositAccountFilter"
                    class="button form__button form__button--lg w-30 save-changes"
                    type="button"
                  >
                    apply
                  </button>
                  <a
                    role="button"
                    data-dismiss="#customDepositAccountFilter"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  GET_ALL_DEPOSITS_ACCOUNTS,
  GET_DEPOSIT_ACCOUNT,
  GET_ALL_DEPOSITS_EXPORTS,
} from "@/core/services/store/deposit.module";
import { mapGetters, mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
// import JsonExcel from "vue-json-excel";
import createAccount from "@/components/deposit/_base-createAccount.vue";
import ApiService from "@/core/services/api.service";
import Papa from "papaparse";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
export default {
  name: "account",
  data: function () {
    return {
      pages: 1,
      size: 10,
      showAccountStatusModal: false,
      loading: false,
      account: {
        accountKey: "",
        accountStatus: "",
      },
      fetchAccount: {
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        isFromFilter: false,
        filterCategory: "",
      },
      accountStatusList: [
        { id: 1, name: "PENDING APPROVAL" },
        { id: 2, name: "APPROVED" },
        { id: 3, name: "ACTIVE" },
        { id: 4, name: "MATURED" },
        { id: 5, name: "LOCKED" },
        { id: 6, name: "DORMANT" },
        { id: 7, name: "CLOSED" },
        { id: 8, name: "CLOSED WRITTEN OFF" },
        { id: 9, name: "CLOSED REJECTED" },
        { id: 10, name: "WITHDRAWN" },
      ],
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Deposit Product has been created",
      },
      json_fields: {
        "Product Name": "prod_name",
        ID: "id",
        Branch: "branch",
        "Account Holder Name": "name",
        "Approved State": "state",
        "Deposit Balance": "balance",
        "Account Officer": "officer",
        "Last Modified Date": "date_mod",
      },
      showFilterModal: false,
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  components: {
    // downloadExcel: JsonExcel,
    DeleteModal,
    createAccount,
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  watch: {
    allDepositExports() {
      this.filterText = this.allDepositExports.filterText
        ? this.allDepositExports.filterText.replace(/_/g, " ")
        : "";
      this.filterEdit = this.allDepositExports.filters
        ? this.allDepositExports.filters
        : [];
    },
    allDepositAccounts() {
      this.filterText = this.allDepositAccounts.filterText
        ? this.allDepositAccounts.filterText.replace(/_/g, " ")
        : "";
      this.filterEdit = this.allDepositAccounts.filters
        ? this.allDepositAccounts.filters
        : [];
      if (
        this.allDepositAccounts.filterText &&
        this.allDepositAccounts.filters
      ) {
        this.getExportData();
      }
    },
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === "" || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 10;
      this.fetchAccount.filterCategory = "deposit";
      this.fetchAccount.isFromFilter = true;
      this.$store
        .dispatch(GET_ALL_DEPOSITS_ACCOUNTS, this.fetchAccount)
        .then(() => {
          this.alertSuccess("Successfully applied filter");
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
    },
    reloadFilter() {
      this.deleteFilter();
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.fetchAccount.filters = [];
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 10;
      this.getAccounts();
    },
    getExportData() {
      if (this.allDepositAccounts.totalCount > 0) {
        let data = this.fetchAccount;
        data.pageSize = this.allDepositAccounts.totalCount;
        this.$store.dispatch(GET_ALL_DEPOSITS_EXPORTS, data);
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    getAccounts() {
      this.$store.dispatch(GET_ALL_DEPOSITS_ACCOUNTS, this.fetchAccount);
      if (!this.userCanViewDepositAccount)
        return this.alertWarning(
          "You do not have permission to view deposit accounts"
        );
    },
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },

    filterAccountsByStatus() {
      return this.products;
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Deposits List Download",
        Subject: "Deposits List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("DepositsList");

      let sheetData = [
        [
          "Product Name",
          "ID",
          "Branch",
          "Account Holder Name",
          "Approved State",
          "Deposit Balance",
          "Account Officer",
          "Last Modified Date",
        ],
      ];

      if (this.allDepositExports && this.allDepositExports.data) {
        this.allDepositExports.data.map((item) => {
          let new_arr = [];
          new_arr.push(item.savingsAccountName);
          new_arr.push(item.uniqueId);
          new_arr.push(item.branchName ? item.branchName : "");
          new_arr.push(`${item.accountFirstName} ${item.accountLastName}`);
          new_arr.push(item.accountStatus);
          new_arr.push(item.balance);
          new_arr.push(item.accountOfficerName);
          new_arr.push(
            item.lastModifiedDate
              ? moment(item.lastModifiedDate).format("DD-MM-YYYY")
              : ""
          );

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["DepositsList"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "deposits.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Product Name", dataKey: "prod_name" },
        { title: "ID", dataKey: "id" },
        { title: "Branch", dataKey: "branch" },
        { title: "Account Holder Name", dataKey: "name" },
        { title: "Approved State", dataKey: "state" },
        { title: "Deposit Balance", dataKey: "balance" },
        { title: "Account Officer", dataKey: "officer" },
        { title: "Last Modified Date", dataKey: "date_mod" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("deposits.pdf");
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        // let nextPage = this.allDepositAccounts.hasNext
        //   ? this.allDepositAccounts.currentPage + 1
        //   : this.allDepositAccounts.hasNextPage ? this.allDepositAccounts.currentPage + 1
        //   : 0;
        this.fetchAccount.pageIndex++;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_DEPOSITS_ACCOUNTS, this.fetchAccount);
      } else if (pages === "previous") {
        // let nextPage = this.allDepositAccounts.hasPrevious
        //   ? this.allDepositAccounts.currentPage - 1
        //   : this.allDepositAccounts.hasPreviousPage ? this.allDepositAccounts.currentPage - 1
        //   : 0;
        this.fetchAccount.pageIndex--;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_DEPOSITS_ACCOUNTS, this.fetchAccount);
      } else if (pages !== null) {
        this.fetchAccount.pageIndex = pages;
        this.fetchAccount.pageSize = pageSize;
        this.$store.dispatch(GET_ALL_DEPOSITS_ACCOUNTS, this.fetchAccount);
      }
    },
    openCreateAccountModal(account) {
      this.showAccountStatusModal = true;
      this.account.accountKey = account.savingsAccountKey;
      this.account.accountStatus = account.accountStatus;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    closeAccountStatusModal() {
      this.showAccountStatusModal = false;
    },
    changeAccountStatus() {
      this.loading = true;
      ApiService.put(`DepositAccount/UpdateDepositAccountStatus`, this.account)
        .then((response) => {
          this.alertSuccess(response.data.message);
        })
        .catch((e) => {
          this.alertError(e.response.data.message);
        })
        .finally(() => {
          this.loading = false;
          this.showAccountStatusModal = false;
          this.getAccounts();
        });
    },
    accountDetails(account) {
      let savingsAccountKey = account.savingsAccountKey;
      this.$store
        .dispatch(GET_DEPOSIT_ACCOUNT, { savingsAccountKey })
        .then(() => {
          this.$router.push({ name: "deposit_view" });
        })
        .catch((e) => {
          this.alertError(e.response.data.message);
        });
    },
    getUpdatedDeposits(value) {
      this.fetchAccount.pageSize = value;
      this.getAccounts();
    },
    changePageIndex(value, pageSize) {
      this.changeCurrentPage(value, pageSize);
    },
    downloadCSVFile() {
      const jsonData = this.allDepositAccounts.data.map((a) => ({
        savingsAccountName: a.savingsAccountName,
        accountFirstName: a.accountFirstName,
        accountLastName: a.accountLastName,
        accountStatus: a.accountStatus,
        balance: a.balance,
      }));

      let blob = new Blob([Papa.unparse(jsonData)], {
        type: "text/csv;charset=utf-8;",
      });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "deposit-account.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    viewRoute(data) {
      this.$router.push({ path: `/deposit-account/${data}` });
    },
  },
  computed: {
    ...mapGetters(["allDepositAccounts", "allDepositExports"]),

    ...mapState({
      products: (state) => state.deposit.depositAccount,
      user: (state) => state.auth.user,
      loadingDepositData: (state) => state.deposit.loadingDepositData,
    }),

    userCanViewDepositAccount() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.DepositAccount
      );
      const { permissionsList } = account;
      return permissionsList.CanViewSavingsAccount;
    },

    userCanExportToExcel() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },

    download() {
      let data = [];
      if (this.allDepositExports && this.allDepositExports.data) {
        this.allDepositExports.data.map((item) => {
          let new_obj = {};
          new_obj.prod_name = item.savingsAccountName;
          new_obj.name = `${item.accountFirstName} ${item.accountLastName}`;
          new_obj.state = item.accountStatus;
          new_obj.balance = item.balance;
          new_obj.officer = item.accountOfficerName;
          new_obj.id = item.uniqueId;
          new_obj.branch = item.branchName ? item.branchName : "";
          new_obj.date_mod = item.lastModifiedDate
            ? moment(item.lastModifiedDate).format("DD-MM-YYYY")
            : "";

          data.push(new_obj);
        });
      }

      return data;
    },
  },
  created() {
    this.getAccounts();
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
  },
};
</script>

<style scoped>
.button--sm.button--cancel span {
  font-size: 1rem;
}
.button--cancel,
.button--grey.text--black {
  font-size: 1rem;
}
.cursor {
  cursor: pointer;
}
</style>
