<template>
  <!-- Create Deposit Account -->
  <div
    v-if="show"
    ref="create-deposit-modal"
    class="modal modal__right"
    id="newDepositModal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <slot name="modal-head"> </slot>
            <slot name="modal-title">
              <h5 class="modal__head__title text--capital">
                Create New Deposit Account
              </h5>
            </slot>
          </div>
          <div class="modal__body">
            <slot name="modal-body"> </slot>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "createAccount",
  props: {
    show: Boolean,
  },
};
</script>

<style scoped>
.no--padding {
  padding: 0 !important;
}
.text--600 {
  font-weight: 600 !important;
  font-family: Montserrat-semibold !important;
}
</style>
